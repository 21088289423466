@font-face {
    font-family: "osLight";
    src: url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Light.eot");
    src:
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Light.eot?#iefix") format("embedded-opentype"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Light.woff2") format("woff2"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Light.woff") format("woff"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Light.ttf") format("truetype"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Light.svg#open_sansbold") format("svg");

    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "osReg";
    src: url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Regular.eot");
    src:
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Regular.woff2") format("woff2"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Regular.woff") format("woff"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Regular.ttf") format("truetype"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Regular.svg#open_sansbold") format("svg");

    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "osSemibold";
    src: url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Semibold.eot");
    src:
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Semibold.woff2") format("woff2"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Semibold.woff") format("woff"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Semibold.ttf") format("truetype"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Semibold.svg#open_sansbold") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "osBold";
    src: url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Bold.eot");
    src:
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Bold.woff2") format("woff2"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Bold.woff") format("woff"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Bold.ttf") format("truetype"),
        url("https://img2.cncenter.cz/images/blesk/nadace/OpenSans-Bold.svg#open_sansbold") format("svg");
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: white;
    font-family: "osReg", Arial, Helvetica;
    max-width: 100%;
    min-width: 970px;
    overflow-x: hidden;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
    font-family: "osLight";
}

/* nadace */

.nad .container .outer_4 {
    margin-left: 50px;
    width: 260px;
}

.logoNadace {
    float: left;
    width: 150px;
    /* height: 120px; */
    padding-top: 20px;
    padding-bottom: 20px;
}
.logoNadace a {
    background: transparent url("https://img2.cncenter.cz/images/blesk/nadace/nadace-logo-2.png") 0 0 no-repeat;
    width: 150px;
    height: 127px;
    display: block;
}
.nad h2 {
    font-size: 46px;
    margin-top: 55px;
    margin-bottom: 15px;
    color: #16212d;
}

.nad a:hover,
.nad .nad-monthly h2 {
    color: #f03d38;
    font-weight: 100;
}
.nad .article #menu {
    border-bottom: 0px;
    float: right;
    margin-left: 0;
    /* float: right; */
}

.nad#body-wrapper {
    background: white !important;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.nad #article .article-body p {
    font-size: 15px;
}

.nad .nad-nfo-container {
    position: relative;
    height: 440px;
    background: #fce9e0;
}
.nad .nad-about-container {
    position: relative;
    height: 270px;
}
.nad .nad-about-bg {
    background: #e9eff4;
    width: 5000px;
    height: 270px;
    position: absolute;
    margin-left: -2060px;
}
.nad .nad-about-txt {
    position: absolute;
    font-family: "osLight";
    font-size: 22px;
    color: #16212d;
}
.nad .nad-about-txt p {
    margin: 0 300px 0 0;
    line-height: 1.3em;
}
.nad .nad-about-img {
    float: right;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-image: url("https://img2.cncenter.cz/images/blesk/nadace/nadace-about.jpg");
    background-position: 0;
    margin-top: 55px;
    margin-right: 60px;
    border-right: 1px solid transparent;
}
.nad .nad-nfo {
    background: #f7ced0 url("https://img2.cncenter.cz/images/blesk/nadace/nadace-pozadi.png") center center no-repeat;
    width: 5000px;
    height: 440px;
    position: absolute;
    margin-left: -1995px;
    display: block;
}
.nad #menu nav > ul > li {
    height: 100px;
    display: inline-block;
    padding: 0;
    z-index: 5;
    position: relative;
}
.nad #menu nav > ul > li:first-of-type {
    margin-left: 10px;
}
.nad #menu nav > ul > li > a {
    display: block;
    float: left;
    font-family: "osReg";
    font-size: 15px;
    padding: 0 14px;
    margin: 75px 0 0 0;
    height: 20px;
    line-height: 20px;
    color: #707b87;
    overflow: hidden;
}
.nad #menu nav > ul > li:last-of-type > a {
    border-right: 0;
}
.nad #menu nav > ul > li > a:hover {
    color: #f03d38;
}

.nad #menu > li {
    position: relative;
}
.nad #menu .dropdown {
    padding: 5px 0 0 0;
    top: 67px;
    right: -30px;
    position: absolute;
    width: 150px;
    height: auto;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    -webkit-box-shadow: 3px 3px 5px -3px #98a3af;
    box-shadow: 3px 3px 5px -3px #98a3af;
}

.nad #menu .dropdown > li {
    display: block;
    background-color: rgba(112, 123, 135, 0.9);
    padding: 10px 20px;
    border-bottom: 1px solid #cad5df;
}
.nad #menu .dropdown > li:first-of-type {
    border-top: 1px solid #cad5df;
}
.nad #menu .dropdown > li:last-of-type {
    border-bottom: 0;
}
.nad #menu .dropdown > li > a {
    font-family: "tablet_gothic_compressed";
    text-transform: uppercase;
    font-size: 16px;
    display: block;
    line-height: 20px;
    height: 18px;
    color: #e9eff4;
}
.nad #menu nav > ul > li:hover > .dropdown,
.nad #menu nav > ul > li > .dropdown:hover {
    visibility: visible;
    opacity: 1;
    right: 0;
}

.nad #menu .dropdown li:hover,
.nad #menu .dropdown li:hover a {
    color: white;
    background-color: rgba(112, 123, 135, 1);
}
.nad .article .headerMenu {
    padding-top: 0;
    margin-top: 0;
}
.nad .article .headerMenu .searchIco {
    float: right;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background: transparent url("https://img2.cncenter.cz/images/blesk/news/icons.png?v=1") 0px 0px no-repeat;
    position: relative;
    /* border-left: 1px solid #cad5df; */
    cursor: pointer;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    margin-top: 75px;
}

.nad .searchIco #searchIco {
    width: 40px;
    height: 30px;
    float: right;
}

.nad .headerMenu .searchIco.searchAct {
    background: transparent url("https://img2.cncenter.cz/images/blesk/news/icons.png?v=1") 0px -30px no-repeat;
}
.nad .searchIco .searchBox {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    top: 20px;
    overflow: hidden;
    width: 0;
    height: 70px;
    z-index: 0;
    right: 0;
    width: auto;
    min-width: 400px;
    height: 38px;
}

.nad .searchIco.searchAct .searchBox {
    visibility: visible;
    opacity: 1;
    white-space: nowrap;
    text-align: right;
    z-index: 100;
    padding: 12px 0 10px 10px;
    margin-top: 10px;
    display: block;
    top: -34px;
    right: 30px;
}

.nad .searchBox form:after {
    display: none;
}
.nad .searchIco.searchAct .searchBox form {
    float: right;
}
.nad .searchIco.searchAct .searchBox form input {
}
.nad .searchBox .searchField {
    float: right;
    width: 290px;
    border: 1px solid #cad5df;
    border-right: 0;
    height: 40px;
    font-size: 14px;
    box-sizing: border-box;
}

.nad .searchBox form .colorBtn {
    float: right;
    background-color: #f03d38;
    font-family: "osLight";
    font-size: 13px;
    text-transform: capitalize;
    font-weight: normal;
    height: 40px;
}
.nad #menu nav > ul > li:last-of-type {
    /* padding-left: 30px; */
}
.nad a.fbIco,
.nad a.twIco {
    width: 26px !important;
    height: 26px !important;
    padding: 0px !important;
    margin: 47px 20px 0 0 !important;
    background: transparent url("https://img2.cncenter.cz/images/blesk/nadace/icons.png") 0 0 no-repeat;
    display: inline-block;
}
.nad a.twIco {
    background-position: -50px 0;
}
.nad a.fbIco:hover {
    background-position: 0px -54px;
}
.nad a.twIco:hover {
    background-position: -50px -54px;
}

.nad #menu nav > ul > li .nad-blesk-logo {
    background: transparent url("https://img2.cncenter.cz/images/blesk/nadace/blesk-logo.png") center center no-repeat;
    display: inline-block;
    width: 84px;
    height: 40px;
    margin-top: 63px;
    background-size: 84px;
}

.nad-monthly {
    padding-left: 240px;
    background: transparent url("https://img2.cncenter.cz/images/blesk/nadace/nadace-logo-white.png") 0 0 no-repeat;
    min-height: 220px;
    height: 220px;
}
.nad-monthly p {
    color: #5c6773;
    font-family: "osLight";
    font-size: 22px;
    line-height: 1.3em;
}

.nad-boxT {
    border-top: 1px solid #cad5df;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 22px;
    color: #5c6773;
    font-family: "osLight";
    font-weight: 100;
}
.nad-boxT a {
    color: #5c6773;
}
.nad-art {
    margin-top: 30px;
    border-bottom: 1px solid #cad5df;
    padding-bottom: 40px;
}
div:last-of-type > .nad-art {
    border-bottom: 0px;
}
.nad-art-img {
    width: 300px;
    float: left;
    height: 210px;
    position: relative;
}
.nad-art-img img {
    width: 300px;
    height: 210px;
}
.nad-art-titles {
    margin-left: 320px;
    position: relative;
    height: 210px;
}
.nad-superTitle {
    height: 25px;
    color: #ff0000;
    font-size: 15px;
    font-family: "osBold";
    font-weight: 100;
    letter-spacing: 0.01em;
}
.nad-subTitle {
    color: #707b87;
    font-size: 11px;
    font-family: "osSemibold";
    position: absolute;
    bottom: 0px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.nad h3.title a {
    font-size: 24px;
    font-family: "osSemibold";
    line-height: 1.3em;
    color: #16212d;
    font-weight: 100;
}

.outer_4 .nad-boxT {
    margin-top: 80px;
    text-align: center;
}
.outer_4 .nad-boxR {
    font-size: 15px;
    color: #5c6773;
    text-align: center;
}

.nad-list {
    border: 1px dashed #cad5df;
    margin: 20px 0 30px 0;
    padding: 30px 10px 35px 10px;
    text-align: center;
}
.nad-list .perex {
    color: #16212d;
    margin-bottom: 15px;
    line-height: 1.3em;
    font-family: "osReg";
}
.nad-list-img {
    width: 158px;
    height: 158px;
    border-radius: 50%;
    display: block;
    background-color: #f4f4f4;
    margin: 0 auto 15px auto;
    background-position: center center;
}
.nad-title a {
    color: #16212d;
    font-family: "osSemibold";
    font-size: 15px;
    display: block;
    margin-bottom: 15px;
    font-weight: 100;
}
a.nad-more {
    color: #4b8afb;
    display: block;
}
.nad-tag-red {
    display: block;
    position: absolute;
    bottom: -10px;
    height: 30px;
    width: 160px;
    text-transform: uppercase;
    left: 70px;
    z-index: 5;
}
.nad .nad-tag-red a,
.nad .nad-tag-red a:hover {
    color: white;
    display: block;
    font-size: 11px;
    font-family: "osBold";
    z-index: 20;
    text-align: center;
    line-height: 29px;
    background-color: #ff0000;
    height: 30px;
    z-index: 10;
    letter-spacing: 0.2em;
    font-weight: 600;
}
.nad-tag-red::after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 0px;
    right: 10px;
    left: auto;
    width: 90%;
    top: 7px;
    max-width: 260px;
    background: #888;
    -webkit-box-shadow: 0 15px 10px #888;
    -moz-box-shadow: 0 15px 10px #888;
    box-shadow: 0 15px 10px #888;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    height: 8px;
}

.nad-tag-red.nad-tag-grey a {
    background-color: #98a3af !important;
}

.nadmenu {
    display: flex;
}

.nad-footer {
    border-top: 1px solid #cad5df;

    margin-top: 20px;
    text-align: center;
    padding-top: 110px;
    background: transparent url("https://img2.cncenter.cz/images/blesk/nadace/nadace-logo-2.png") center 20px no-repeat;
    background-size: 80px;
}
.nad-footer .nadmenu {
    justify-content: center;
    margin: 10px 0 30px;
}
.nad-footer .nadmenu li {
    display: inline-block;
    padding: 5px 30px;
    font-size: 15px;
}
.nad-footer .nadmenu li a {
    color: #707b87;
}
.nad-share {
    margin-bottom: 55px;
}
.nad-copy {
    height: 80px;
    line-height: 27px;
    position: relative;
    padding-top: 20px;
}
.nad-copy a,
.nad-copy a:hover {
    color: #707b87;
}
.nad-copy-bg {
    background: #f7f9fa;
    width: 5000px;
    height: 80px;
    position: absolute;
    margin-left: -2060px;
}
.nad-copy-txt {
    color: #707b87;
    line-height: 20px;
    padding-top: 20px;
    position: absolute;
    width: 100%;
    font-size: 14px;
}

/* clanek */

.nad article > .outer_8 {
    margin-top: 30px;
    border-top: 1px solid #cad5df;
}
.nad #article h1 {
    font-family: "osSemibold";
    font-size: 40px;
    line-height: 1.3em;
    font-weight: 100;
}
.nad #article .nad-supertitle {
    font-family: "osLight";
    padding: 15px 0;
    color: #ff0000;
    font-size: 22px;
    font-weight: 100;
}
.nad #article .dateTime {
    float: none;
    margin: 0;
    width: auto;
    text-align: left;
    padding: 15px 0;
    font-weight: 200;
}

.nad #article .leadsection p {
    font-size: 15px;

    font-family: "osSemibold";
    font-weight: 100;
    line-height: 1.6em;
}

.nad .relatedWrapper {
    width: 100%;
    float: none;
    display: block;
    background-color: #f7f9fa;
}
.nad .relatedArticles {
    background-color: #f7f9fa;
    width: 100%;
    display: block;
    padding: 10px;
}

.nad #article .relatedArticles .header h4 {
    font-size: 22px;

    font-family: "osLight";
    font-weight: 100;
    color: #ff0000;
    margin: 0;
    line-height: 40px;
}

.nad #article .relatedArticles .article-paid {
    paddig-top: 20px;
}

.nad #article .relatedArticles .article a.title {
    font-size: 17px;

    font-family: "osSemibold";
    width: 400px;
    line-height: 1.3em;
    font-weight: 100;
    text-decoration: none;
}
.nad #article .relatedArticles .article a.more {
    display: block;

    float: right;
    height: 68px;
    line-height: 68px;
    text-decoration: none;
    color: #ff0000;
    font-family: "osReg";
    font-weight: 100;
    font-size: 15px;
}

.nad #article .relatedArticles .article a:hover {
    text-decoration: underline;
    color: #ff0000;
}
.nad #article .topGallery {
    position: relative;
    left: 0px;
    margin-top: 0px;
}

.nad #article .author {
    font-size: 15px;
}

.outer_4 .nad-boxT {
    margin-top: 30px;
}

.nad .icon.iconPlay,
.nad .icon.iconPhoto {
    display: none;
}

.quote-dms {
    background-color: #f7f9fa;
    padding: 35px;
    text-align: center;
    font-size: 15px;
    margin: 20px 0;
}
.quote-dms p {
    margin-bottom: 0;
}
.quote-dms p:first-of-type {
    margin-bottom: 10px;
}
.quote-dms big {
    font-family: "osSemibold";
    color: #ff0000;
    font-size: 22px;
    text-transform: uppercase;
}
.quote-dms small {
    font-size: 12px;
}

.nadace-icon {
    background-position: right 175px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 50px;
}
.nadace-icon-tri,
.nadace-icon-TRI {
    background-image: url("https://img2.cncenter.cz/images/blesk/nadace/nadace-tri.png");
}
.nadace-icon-alka,
.nadace-icon-ALKA {
    background-image: url("https://img2.cncenter.cz/images/blesk/nadace/nadace-alka.png");
}
.nad-menu {
    padding-top: 30px;

    overflow: hidden;
    position: relative;
    margin-bottom: 0px;
}

.nad-menu:after {
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    top: 30px;
    height: 50px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0) 95%, rgba(255, 255, 255, 0.9) 100%);
    pointer-events: none;
}

.nad-menu > .subSections {
    position: relative;
    width: 10000px;
    padding: 0 20px;
}
.nad-menu > .subSections > li {
    display: inline-block;

    height: 52px;
    background-color: transparent;
    overflow: hidden;
    border-left: 1px solid #cad5df;
    width: auto;
    white-space: nowrap;
    /* min-width: 500px; */
    padding: 0 1px 0 10px;
}
.nad-menu > .subSections > li > a {
    font-size: 13px;

    color: #5c6773 !important;
    width: 100px;
    display: block;
    text-align: center;
    margin-left: -60px;
    position: absolute;
    top: -18px;
    font-weight: bold;
    z-index: 100;
    box-sizing: border-box;
}
.nad-menu > .subSections > li > ul > li > a {
    display: inline-block;
    height: 51px;
    width: 90px;
    overflow: hidden;
    line-height: 50px;
    text-align: center;
    border: 1px solid #cad5df;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #eff2f5;
    border-bottom: 0px;
    margin: 0px 5px 0 0;
    color: #929ba3 !important;
    font-size: 15px;
    font-family: "osReg";
    font-weight: normal;
    z-index: 0;
}
.nad-menu > .subSections > li > ul > li > a:hover {
}
.nad-menu > .subSections > li > ul > li.active > a {
    background-color: white;

    color: #16212d !important;
    z-index: 10;
    position: absolute;
    top: 0px;
    height: 52px;
}
.nad-menu > .subSections > li > ul {
    display: table-row;
}
.nad-menu > .subSections > li > ul > li {
    display: table-cell;
    width: 97px;
}
.nad-menu-line {
    border: 1px solid #cad5df;

    border-bottom: 0px;
    border-right: 0px;
    border-left: 0;
    height: 5px;
    border-top-left-radius: 3px;
    background-color: white;
    z-index: 2;
    position: absolute;
    bottom: -2px;
    width: 100%;
    box-sizing: border-box;
    left: 0px;
}
.nad.isTitle > .container > .grid_8 {
    border-left: 1px solid #cad5df;
    width: 638px;
    border-right: 1px solid #cad5df;
    overflow-x: hidden;
    /* border-top-left-radius: 3px; */
    border-bottom: 1px solid #cad5df;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.nad-years {
    font-family: "osLight";

    font-size: 19px;
    color: #5c6773;
    margin-top: 20px;
    margin-bottom: 0px;
}

.nad #article .imgInArt {
    border: 0px;
    background: white;
    background: transparent;
}

.nad .pagination {
    margin-top: 25px;
}
.nad .pagination .pageBefore,
.nad .pagination .pageAfter,
.nad .pagination li .nad .pagination a.pageBefore,
.nad .pagination a.pageAfter,
.nad .pagination li a {
    font-weight: normal;
    font-size: 15px;
}
.nad .pagination .pageAfter {
    margin-right: 25px;
}
.nad .pagination li a {
    font-weight: bold;
}
.nad .pagination li.aktpage a {
    color: #ff0000;
}
.nad .pagination .disabled {
    color: #cad5df;
}
.nad .pagination .pageBefore {
    float: left;
}
.nad .pagination .pageAfter {
    float: right;
}
.nad .pagination ul,
.nad .pagination li {
    display: inline-block;
}

.nad .article-body ul {
    list-style-type: disc;
    margin-left: 20px;
}

.nad .relativeObject:not(.listedRelatedReal) {
    margin: 0;
}

.nad .scroller {
    position: absolute;
    bottom: 2px;
    z-index: 20;
    width: 50px;
    height: 50px;
    text-align: right;
    cursor: pointer;
}
.nad .scrollFw.scroller {
    right: -55px;
}
.nad .scrollBa.scroller {
    left: -55px;
}

.nad .scrollBa.scroller:after {
    content: "";
    width: 10px;
    height: 10px;
    border-top: 3px solid #c0c0c0;
    border-left: 3px solid #c0c0c0;
    display: block;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-top: 18px;
    float: right;
    pointer-events: none;
}

.nad .scrollFw.scroller:after {
    content: "";
    width: 10px;
    height: 10px;
    border-top: 3px solid #c0c0c0;
    border-left: 3px solid #c0c0c0;
    display: block;
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    margin-top: 18px;
    float: left;
}

.nad .scroller:hover:after {
    border-color: #555555;
}

.nad .subSections {
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}

.faq {
    background-color: white;
    border-radius: 4px;
    padding: 0px;
}

.faq .imgInArt.relativeObject img {
    width: 85px;
}

#article .article-body .faq h3 {
    margin: 0;
}

.accordion .accordion {
    margin: 0;
    background-color: #eaedf0;
    padding: 0px 15px;
    border-radius: 4px;
}
@media (min-width: 768px) {
    .accordion .accordion {
        margin: 0 50px 0 25px;
        background-color: #eaedf0;
        padding: 0px 30px;
    }
}
.faq h3 {
    padding: 10px 0px !important;
    border-top: 1px solid #c8cdd2;
    position: relative;
}
@media (min-width: 768px) {
    .faq h3 {
        padding: 20px 0px !important;
    }
}
.faq h3:first-of-type {
    border-top: 0px;
}
.faq h3.ui-state-default:after,
.faq h3.ui-state-active:after {
    content: "";
    position: absolute;
    background: transparent url("https://img2.cncenter.cz/images/bleskmobil/2015/sprites.png?v=1") 1000px 0px no-repeat;
    background-position: -135px -18px;
    width: 16px;
    height: 14px;
    right: -5px;
    top: 15px;
}
@media (min-width: 768px) {
    .faq h3.ui-state-default:after,
    .faq h3.ui-state-active:after {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
.faq h3.ui-state-active:after {
    background-position: -135px -2px;
}
.faq p {
    margin: 0 0 15px 0;
}
.faq img {
    max-width: 100%;
}
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ui-accordion .ui-accordion-header {
    display: block;
    cursor: pointer;
    position: relative;
    margin: 0;
    min-height: 0;
    padding: 0px 15px 0px 0px;
    outline: none;
    color: #eb1e2d;
    font-family: tablet_gothic_condensed;
    font-size: 18px;
    text-transform: uppercase;
}
@media (min-width: 768px) {
    .ui-accordion .ui-accordion-header {
        font-size: 25px;
        padding: 0.5em 0.5em 0.5em 0.7em;
        margin: 2px 0 0 0;
    }
}

.ui-accordion .ui-accordion-icons {
    padding-left: 2.2em;
}
.ui-accordion .ui-accordion-icons .ui-accordion-icons {
    padding-left: 2.2em;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    position: absolute;
    left: 0.5em;
    top: 50%;
    margin-top: -8px;
}
.ui-accordion .ui-accordion-content {
    padding: 0 0 20px 0;
    border-top: 0;
    overflow: auto;
    font-family: osReg;
    color: #1e2d3c;
    font-size: 15px;
}

.nadPB {
    margin: 0 15px;
    border-bottom: 1px solid #cad5df;
}

.nad-grant-popup {
    display: block;
    width: 570px;
    position: fixed;
    background: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.55);
    z-index: 99999;
    left: 50%;
    margin-left: -290px;
    top: 182px;
    padding: 10px 10px 20px;
    text-align: center;
}

.close {
    font-size: 30px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    right: 15px;
    cursor: pointer;
    color: #ed1b24;
}

@media (max-width: 1024px) {
    body {
        padding: 0px 25px;
    }
    .nad .nad-nfo-container {
        overflow: hidden;
        margin: 0 -25px;
    }
    .nad .scrollFw.scroller {
        right: -35px;
    }
    .nad .scrollBa.scroller {
        left: -35px;
    }
    .nad .scroller {
        width: 30px;
    }
    .nad-copy {
        margin: 0 -25px;
        overflow: hidden;
    }
}
